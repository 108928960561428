import { fetchMenu } from '@/store/features/menu/slice'
import { useAppDispatch } from '@/store/hook'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  fetchUser,
  selectToken,
  selectUserInfo,
} from '@/store/features/user/slice'
import { useSelector } from 'react-redux'
import FullScreenLoading from '../FullScreenLoading'
import { WHITELIST } from '@/constant/whitelist'
import FlightLoading from '@/components/common/FlightLoading';

interface Props {
  children: JSX.Element
}

// 上面这种是比较适合通过动态菜单数据进行配置
// 当然，你也可以在这里设置白名单，白名单都不需要登录认证

const Authority: React.FC<Props> = ({ children }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const token = useSelector(selectToken)
  const dispatch = useAppDispatch()
  const userInfo = useSelector(selectUserInfo)
  const [loading, setLoading] = useState(false)

  const fetchUserInfoAndMenus = useCallback(async () => {
    setLoading(true)
    try {
      await dispatch(fetchUser()).unwrap()
      await dispatch(fetchMenu()).unwrap()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [dispatch])

  useEffect(() => {

    if (location.pathname == '/sso' || location.pathname == '/quickLogin' ) {
      return;
    }
    if (!WHITELIST.includes(location.pathname) && !token) {
      navigate(`/login?redirect=${location.pathname}${location.search}`, {
        replace: true,
      })
    } else if (token && userInfo) {
      if (location.pathname === '/login') {
        navigate(`/`, {
          replace: true,
        })
      }
    }
  }, [location, navigate, token, userInfo])

  useEffect(() => {
    if (token && !userInfo) {
      fetchUserInfoAndMenus()
    }
  }, [token, userInfo, fetchUserInfoAndMenus])

  if (loading) {
    // return <FullScreenLoading tip={'Loading.12..'}></FullScreenLoading>
    return <FlightLoading loading={loading} />
  }

  return children
}
export default Authority
